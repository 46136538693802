import { Dispatch, Action } from 'redux';
import { AjaxError } from 'rxjs/ajax';
import { typePending, typeComplete, typeFail } from 'state-domains/utils';

import { ShimState, BaseAction } from '../../../types';
import {
    LIST_MESSAGES,
    POLL_MESSAGES,
    UPDATE_MESSAGE,
    BULK_READ_MESSAGES,
    BULK_UNREAD_MESSAGES,
    DELETE_MESSAGE,
    BULK_DELETE_MESSAGES,
    CLEAR_MESSAGES,
    GET_TOKEN,
} from '../../../types/actionTypes';
import { userState } from '../../user';
import { domainAction, Dispatcher } from '../../utils';
import { notificationsShim as shim } from '../shim';
import {
    NSQueryProps,
    MessagesPayload,
    UidKeysPayload,
    PollQueryProps,
    UpdateMessagesPayload,
    PollMessagesPayload,
    TokenPayload,
    DeleteNotificationsFailurePayload,
    UpdateNotificationsFailurePayload,
} from '../types';

import { actions as pactions } from './preferences/actions';
import { selectors } from './selectors';
import { withToken } from './utils';

const { nextLink, prevLink } = selectors;

const {
    selectors: { audienceUid },
} = userState;

const TIMEOUT_CODE = 408;

function loadMessages(queryProps: NSQueryProps = {}) {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const audience = audienceUid(getState());
        dispatch({ type: typePending(LIST_MESSAGES) });
        const shimFunction = withToken(dispatch, getState, shim.loadMessages);
        shimFunction(audience, queryProps).subscribe({
            next: (payload: MessagesPayload) => {
                dispatch({ payload, type: typeComplete(LIST_MESSAGES) });
            },
            error: (err: Error) => dispatch({ type: typeFail(LIST_MESSAGES), payload: err }),
        });
    };
}

function loadMessagesLink(url?: string | null) {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        if (url === undefined || url === null) {
            return;
        }
        dispatch({ type: typePending(LIST_MESSAGES) });
        const shimFunction = withToken(dispatch, getState, shim.loadMessagesLink);
        shimFunction(url).subscribe({
            next: (payload: MessagesPayload) => {
                dispatch({ payload, type: typeComplete(LIST_MESSAGES) });
            },
            error: (err: Error) => dispatch({ type: typeFail(LIST_MESSAGES), payload: err }),
        });
    };
}

function loadNextMessages() {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const url = nextLink(getState());
        loadMessagesLink(url)(dispatch, getState);
    };
}

function loadPrevMessages() {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const url = prevLink(getState());
        loadMessagesLink(url)(dispatch, getState);
    };
}

function messagesSeen() {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const url = selectors.seenLink(getState());
        if (url !== '') {
            const shimFunction = withToken(dispatch, getState, shim.messagesSeen);
            shimFunction(url).subscribe();
        }
    };
}

function pollMessages(props: PollQueryProps) {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const audience = audienceUid(getState());
        dispatch({ type: typePending(POLL_MESSAGES) });
        const shimFunction = withToken(dispatch, getState, shim.pollMessages);
        shimFunction(audience, props).subscribe({
            next: (payload: PollMessagesPayload) => {
                dispatch({ payload, type: typeComplete(POLL_MESSAGES) });
                const cursor = payload.next
                    ? new URL(payload.next || '').searchParams.get('cursor') || undefined
                    : undefined;
                pollMessages({ cursor, limit: props.limit })(dispatch, getState);
            },
            error: (err: AjaxError) => {
                if (err.status === TIMEOUT_CODE) {
                    pollMessages(props)(dispatch, getState);
                } else {
                    // need to refresh to starting polling again
                    dispatch({ type: typeFail(POLL_MESSAGES), payload: err });
                }
            },
        });
    };
}

function updateMessage(messageUid: string, read: boolean) {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const url = selectors.selfLink(getState(), { messageUid });
        const notificationsIdList = [messageUid];
        const payload: UpdateMessagesPayload = { read, uids: notificationsIdList };
        dispatch({ payload, type: typePending(UPDATE_MESSAGE) });
        const shimFunction = withToken(dispatch, getState, shim.updateMessage);
        shimFunction(url, read).subscribe({
            next: () => {
                dispatch({ payload: { ...payload, read }, type: typeComplete(UPDATE_MESSAGE) });
            },
            error: (err: Error) => {
                const action: BaseAction<UpdateNotificationsFailurePayload> = {
                    type: typeFail(UPDATE_MESSAGE),
                    payload: {
                        error: err,
                        notificationsIdList,
                    },
                };
                dispatch(action);
            },
        });
    };
}

function bulkReadMessages(uids: string[]) {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const audience = audienceUid(getState());
        const payload: UpdateMessagesPayload = { uids, read: true };
        dispatch({ payload, type: typePending(BULK_READ_MESSAGES) });
        const shimFunction = withToken(dispatch, getState, shim.bulkReadMessages);
        shimFunction(audience, uids).subscribe({
            next: () => {
                dispatch({
                    payload: { ...payload, read: true },
                    type: typeComplete(BULK_READ_MESSAGES),
                });
            },
            error: (err: Error) => {
                const action: BaseAction<UpdateNotificationsFailurePayload> = {
                    type: typeFail(BULK_READ_MESSAGES),
                    payload: {
                        error: err,
                        notificationsIdList: uids,
                    },
                };
                dispatch(action);
            },
        });
    };
}

function bulkUnreadMessages(uids: string[]) {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const audience = audienceUid(getState());
        const payload: UpdateMessagesPayload = { uids, read: false };
        dispatch({ payload, type: typePending(BULK_UNREAD_MESSAGES) });
        const shimFunction = withToken(dispatch, getState, shim.bulkUnreadMessages);
        shimFunction(audience, uids).subscribe({
            next: () => {
                dispatch({
                    payload: { ...payload, read: false },
                    type: typeComplete(BULK_UNREAD_MESSAGES),
                });
            },
            error: (err: Error) => {
                const action: BaseAction<UpdateNotificationsFailurePayload> = {
                    type: typeFail(BULK_UNREAD_MESSAGES),
                    payload: {
                        error: err,
                        notificationsIdList: uids,
                    },
                };
                dispatch(action);
            },
        });
    };
}

function deleteMessage(messageUid: string) {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const url = selectors.selfLink(getState(), { messageUid });
        const notificationsIdList = [messageUid];
        const payload: UidKeysPayload = { uids: notificationsIdList };
        dispatch({ payload, type: typePending(DELETE_MESSAGE) });
        const shimFunction = withToken(dispatch, getState, shim.deleteMessage);
        shimFunction(url).subscribe({
            next: () => {
                dispatch({ payload, type: typeComplete(DELETE_MESSAGE) });
            },
            error: (err: Error) => {
                const action: BaseAction<DeleteNotificationsFailurePayload> = {
                    type: typeFail(DELETE_MESSAGE),
                    payload: {
                        error: err,
                        notificationsIdList,
                    },
                };
                dispatch(action);
            },
        });
    };
}

function bulkDeleteMessages(uids: string[]) {
    return (dispatch: Dispatch<Action>, getState: () => ShimState) => {
        const audience = audienceUid(getState());
        const payload: UidKeysPayload = { uids };
        dispatch({ payload, type: typePending(BULK_DELETE_MESSAGES) });

        const shimFunc = withToken(dispatch, getState, shim.bulkDeleteMessages);
        shimFunc(audience, uids).subscribe({
            next: () => {
                dispatch({ payload, type: typeComplete(BULK_DELETE_MESSAGES) });
            },
            error: (err: Error) => {
                const action: BaseAction<DeleteNotificationsFailurePayload> = {
                    type: typeFail(DELETE_MESSAGE),
                    payload: {
                        error: err,
                        notificationsIdList: uids,
                    },
                };
                dispatch(action);
            },
        });
    };
}

function clearMessages(uids: string[]) {
    return (dispatch: Dispatch<Action>, _getState: () => ShimState) => {
        dispatch({ payload: { uids }, type: CLEAR_MESSAGES });
    };
}

function fetchNotificationsToken() {
    return domainAction(GET_TOKEN, (dispatcher: Dispatcher<TokenPayload>) => {
        dispatcher.pending();
        shim.fetchNotificationToken().subscribe({
            next: (token: any) => dispatcher.complete({ token }),
            error: (error: Error) => dispatcher.fail({ error, token: null }),
        });
    });
}

export const actions = {
    clearMessages,
    messagesSeen,
    loadMessages,
    loadNextMessages,
    loadPrevMessages,
    pollMessages,
    updateMessage,
    bulkReadMessages,
    bulkUnreadMessages,
    deleteMessage,
    bulkDeleteMessages,
    fetchNotificationsToken,
    ...pactions,
};
